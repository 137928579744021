<template>
  <div
    class="bbg text-center"
  >
    <v-img
      contain
      max-width="500"
      src="../assets/img/download/bg.webp"
      class="mx-auto"
    >
      <a
        v-if="ios"
        class="btn-box"
        @click="iosapp = true"
      />
      <a
        v-else
        class="btn-box"
        :href="downloadurl"
        target="_blank"
      />

      <swiper
        class="swiper s1"
        :options="swiperS1"
      >
        <swiper-slide>
          <div class="pa-3">
            <v-img
              src="../assets/img/download/lt1.png"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pa-3">
            <v-img
              src="../assets/img/download/lt2.png"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="pa-3">
            <v-img
              src="../assets/img/download/lt3.png"
              alt=""
            />
          </div>
        </swiper-slide>
        <div
          slot="button-prev"
          class="swiper-button-prev swiper-navBtn S1-prev"
        />
        <div
          slot="button-next"
          class="swiper-button-next swiper-navBtn S1-next"
        />
        <div
          slot="pagination"
          class="swiper-pagination S1-pg"
        />
      </swiper>

      <swiper
        class="swiper s2"
        :options="swiperS2"
      >
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt1.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt2.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt3.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt4.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt5.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <v-img
              src="../assets/img/download/jt6.jpg"
              alt=""
            />
          </div>
        </swiper-slide>
        <div
          slot="button-prev"
          class="swiper-button-prev swiper-navBtn S2-prev"
        />
        <div
          slot="button-next"
          class="swiper-button-next swiper-navBtn S2-next"
        />
        <div
          slot="pagination"
          class="swiper-pagination S2-pg"
        />
      </swiper>
    </v-img>
    <a
      v-if="ios"
      @click="iosapp = true"
    >
      <img
        class="down-btn mx-auto mt-n16 mb-n2"
        src="../assets/img/download/down-btn.webp"
        alt=""
      >
    </a>
    <a
      v-else
      :href="downloadurl"
      target="_blank"
    >
      <img
        class="down-btn mx-auto mt-n16 mb-n2"
        src="../assets/img/download/down-btn.webp"
        alt=""
      >
    </a>

    <template>
      <v-dialog
        v-model="iosapp"
        width="400"
        content-class="dialog-end"
      >
        <v-sheet
          dark
          color="nav_bg"
          class="pa-5 box-down-arrow rounded-lg"
        >
          <div class="position-relative">
            <v-btn
              fab
              icon
              small
              absolute
              top
              right
              color="grey darken-2"
              class="mr-n8"
              @click="iosapp = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <h5 class="pb-3 text--secondary">
            {{ $t('azdsjzm') }}
          </h5>
          <div class="text-body-2 text--disabled">
            {{ $t('azdsjzmnr1') }}<v-icon
              color="blue"
              class="mx-1 mt-n2"
            >
              mdi-export-variant
            </v-icon>{{ $t('azdsjzmnr2') }}
          </div>
          <div class="mt-3 d-flex justify-space-around">
            <v-btn
              small
              outlined
              color="primary"
              @click="iosapp_help = true"
            >
              {{ $t('rhcz') }}?
            </v-btn>
            <v-btn
              small
              outlined
              color="primary"
              @click="iosapp_help = false"
            >
              {{ $t('wyaz') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-dialog>

      <v-dialog
        v-model="iosapp_help"
        width="400"
      >
        <v-card
          color="box_bg"
        >
          <v-card-title
            dense
            class="text-body-1"
          >
            {{ $t('rhcz') }}?
            <v-spacer />
            <v-btn
              fab
              icon
              small
              color="grey darken-2"
              @click="iosapp_help = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-carousel
              hide-delimiters
              :continuous="false"
              height="100%"
            >
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios01.jpg"
                >
              </v-carousel-item>
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios02.jpg"
                >
              </v-carousel-item>
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios03.jpg"
                >
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import store from "../store/"
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    downloadurl: '',
    ios: false,
    iosapp: false,
    iosapp_help: false,

    swiperS1: {
      slidesPerView: 1,
      spaceBetween: 8,
      centeredSlides: true,
      loop: true,
      fade: true,
      grabCursor: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.S1-pg',
        clickable: true
      },
      navigation: {
        nextEl: '.S1-next',
        prevEl: '.S1-prev'
      }
    },

    swiperS2: {
      slidesPerView: 1.3,
      spaceBetween: 8,
      centeredSlides: true,
      loop: true,
      fade: true,
      grabCursor: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.S2-pg',
        clickable: true
      },
      navigation: {
        nextEl: '.S2-next',
        prevEl: '.S2-prev'
      }
    },

    sticky: false,

  }),
  watch:{

  },
  created() {
    if(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
      this.ios = true
    }
  },
  mounted() {
    this.downloadurl = this.$store.state.download
  },
  methods: {

  }
}
</script>

<style>
  .bbg{
    background-color: #f00;
    background-image: url("../assets/img/download/bg-low.webp");
    background-repeat: repeat-x;
    background-size: auto 100%;
  }
  .btn-box{
    display: block!important;
    width: 100%;
    height: 3.5%;
    margin-top: 100%;
  }
  .s1{
    margin-top: 108%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .s2{
    margin-top: 29%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .swiper-navBtn{
      color: rgba(255, 255, 255, .3)!important;
      transition: color 0.3s ease;
  }
  .swiper-navBtn:hover{
      color: rgba(255, 255, 255, .8)!important;
  }
      .swiper-navBtn::before,
      .swiper-navBtn::after{
      font-size: 35px;
  }

  .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1!important; 
  }

  .swiper-pagination-bullet {
      width: 0.625rem!important;
      height: 0.625rem!important;
      border-radius: .3125rem!important;
      background-color: var(--v-box_bg-base)!important;
      margin: 0 0.3125rem!important;
      cursor: pointer;
      transition: width .3s linear;
      opacity: 0.4!important;
  }

  .swiper-pagination-bullet-active {
      background-color: var(--v-box_bg-base)!important;
      width: 1.75rem!important;
      opacity: 0.8!important;
  }

  .down-btn {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    max-width: 500px;
    z-index: 2;
  }

  @media (max-width: 767px) {
    /* 在移动端应用的样式 */
    .down-btn {
      bottom: 65px;
    }
  }

  @media (min-width: 768px) {
    /* 在PC端应用的样式 */
    .down-btn {
      bottom: 0px;
    }
  }
</style>